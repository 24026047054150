const body = document.body;
const is_mobile = body.dataset.isMobile === 'true';
const is_desktop = !is_mobile;
const is_authenticated = body.dataset.isAuthenticated === 'true';
const is_darkmode = body.dataset.isDarkmode === 'true';
const is_debug = body.dataset.isDebug === 'true';


function getCSRFToken() {
    let csrftoken = document.querySelector('[name=csrfmiddlewaretoken]');
    if (csrftoken) {
        return csrftoken.value;
    }else {
        return '';
    }
}

$(document).ready(function () {
    if (is_mobile) {
        if($(window).width() >= 768) {
            // show toc
            localStorage.toc = "show";
            $('#toc_on').prop('checked', true);
            $(".toc-checker").removeClass("hide");
            $(".toc-checker").show();

        }else {
            // hide toc
            localStorage.toc = "hide";
            $('#toc_off').prop('checked', true);
            $(".toc-checker").hide();
        }
        
        $(".mobile-toc").click(function() {
            if ($(".toc-checker").is(":visible")) {
                $('#toc_on').prop('checked', false);
                $(".toc-checker").addClass("hide");
                $(".toc-checker").hide();
            } else {
                $('#toc_on').prop('checked', true);
                $(".toc-checker").removeClass("hide");
                $(".toc-checker").show();
            }
        });
    }

    $('.bookmark_btn').click(function(){
        var pathname = window.location.pathname;
        $.ajax({
            url: "/bookmark/",
            method: "post",
            data: {pathname: pathname},
            success: function(data){
                $('#bookmarkModal .modal-body').html(data);
                $('#bookmarkModal').modal("show");
            }
        });
    });

    $('#bookmarkModal .btn-primary').click(function(){
        var pathname = window.location.pathname;
        // console.log(pathname);
        $.ajax({
            url: "/bookmark/add/",
            method: "post",
            data: {pathname: pathname},
            success: function(data){
                // console.log(data);
                $('#bookmarkModal .modal-body').html(data);
            },
            error: function(jqXHR, textStatus, errorThrown) {
                console.log("Request failed: " + textStatus + ", " + errorThrown);
                // $('#bookmarkModal .modal-body').html("An error occurred: " + textStatus);
            },
        });
    });

    $('#bookmarkModal .item-delete').click(function(){
        
        $.ajax({
            url: "/bookmark/delete/",
            method: "post",
            data: {pathname: pathname},
            success: function(data){
                $('#bookmarkModal .modal-body').html(data);
            }
        });
    });

    $('[data-toggle="popover"]').popover({
        trigger: 'hover', // 마우스를 올렸을 때 popover 표시
        container: 'body' // popover가 body 요소에 추가되도록 설정
    });

});
function deleteBookmark(bookmarkId) {
    if (confirm('정말 삭제하시겠습니까?')) {
        $.ajax({
            url: '/bookmark/delete/' + bookmarkId + '/',
            type: 'POST',
            data: {
                'csrfmiddlewaretoken': getCSRFToken()
            },
            success: function(response) {
                $('#bookmarkModal .modal-body').html(response);
            },
            error: function(xhr, status, error) {
                // 오류 처리
                alert('삭제 실패: ' + error);
            }
        });
    }
}

$(document).ready(function() {
    // 현재 다크모드 상태 확인
    let isDarkMode = is_darkmode;

    // 다크모드 토글 함수
    function toggleDarkMode() {
        isDarkMode = !isDarkMode;
        updateTheme();
        saveDarkModePreference();
    }

    // 테마 업데이트 함수
    function updateTheme() {
        const timestamp = new Date().getTime(); 
        const mainStylesheet = $('#main-stylesheet');
        const markdownStylesheet = $('#markdown-stylesheet');
        const highlightStylesheet = $('#highlight-stylesheet');

        if (isDarkMode) {
            mainStylesheet.attr('href', `${staticFiles.mainDark}?v=${timestamp}`);
            markdownStylesheet.attr('href', `${staticFiles.markdownDark}?v=${timestamp}`);
            highlightStylesheet.attr('href', `${staticFiles.highlightDark}?v=${timestamp}`);
        } else {
            mainStylesheet.attr('href', `${staticFiles.mainLight}?v=${timestamp}`);
            markdownStylesheet.attr('href', `${staticFiles.markdownLight}?v=${timestamp}`);
            highlightStylesheet.attr('href', `${staticFiles.highlightLight}?v=${timestamp}`);
        }
    }

    // Ajax를 사용하여 다크모드 설정 저장
    function saveDarkModePreference() {
        $.ajax({
            url: '/set_darkmode/',  // Django URL을 여기에 지정
            method: 'POST',
            data: {
                'darkmode': isDarkMode ? 'on' : 'off'
            },
            headers: {
                'X-CSRFToken': getCookie('csrftoken')  // CSRF 토큰
            }
        });
    }

    // CSRF 토큰을 쿠키에서 가져오는 함수
    function getCookie(name) {
        let cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }

    // 토글 버튼 클릭 이벤트
    $('#toggle-theme').click(toggleDarkMode);

    // 초기 테마 설정
    // updateTheme();
});

$(document).ready(function() {
    // 이전 버튼 클릭 시 이벤트
    $('#prev-btn').click(function(event) {
        event.preventDefault();
        var previousLink = $('.prev_page_link');
        if (previousLink.length) {
            previousLink[0].click();
        }
    });

    $('#next-btn').click(function(event) {
        event.preventDefault();
        var nextLink = $('.next_page_link');
        if (nextLink.length) {
            nextLink[0].click();
        }
    });
});